.footerContainer {
  width: 100%;
  padding: 20px 20px;
  margin-top: auto;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* height: 100px; */
}
.copyright-text,
.privacy-policy-text p {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: var(--blue);
  text-align: center;
}

@media screen and (min-width: 768px) {
  .copyright-text,
  .privacy-policy-text p {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
}
@media screen and (min-width: 1024px) {
  .footerContainer {
    flex-direction: row;
    justify-content: space-between;
  }
}
